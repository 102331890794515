import { Component, OnInit } from '@angular/core';
import { StringDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
    selector: 'soft-dynamic-string-input',
    templateUrl: './string-input.component.html',
    styleUrls: ['./string-input.component.scss'],
    standalone: false
})
export class StringInputComponent
  extends DynamicInputAtom<StringDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
