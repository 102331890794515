import { ControlValueAccessor } from '@angular/forms';
import { effect, inject, output, OutputEmitterRef, Signal, signal, WritableSignal } from '@angular/core';
import { equals, Store } from '@softline/core';
import * as ModalStore from '../../../modal/modal.store';
import { DateParser } from '../../../services/date.parser';
import { SOFTLINE_FEATURE_MODAL } from '../../../modal/modal.shared';
import { DateRange } from '../../../modal/data/date-range-modal-config';

export abstract class DateRangeFieldInput implements ControlValueAccessor {
  onChange: Function = () => {};
  onTouch: Function = () => {};

  store = inject(Store);
  dateParser = inject<DateParser<string>>(DateParser);

  abstract textInput: WritableSignal<string | null>;
  abstract disabled: WritableSignal<boolean>;
  abstract value: WritableSignal<string | null>;
  abstract valueChange: OutputEmitterRef<string | null>;
  abstract blur: OutputEmitterRef<any>;

  abstract valueInput: Signal<string | null>;
  abstract disabledInput: Signal<boolean>;
  abstract readonly: Signal<boolean>;
  abstract placeholder: Signal<string>;
  abstract changeTrigger: Signal<'blur' | 'input'>;
  protected abstract field: 'from' | 'to';
  abstract format: Signal<string>;
  abstract otherControl: Signal<DateRangeFieldInput | null>;

  valueInputEffect = effect(() => {
    const fromValue = this.valueInput() ?? null;
    this.value.set(fromValue);
  }, {allowSignalWrites: true})

  disabledInputEffect = effect(() => {
    const disabled = this.disabledInput() ?? null;
    this.disabled.set(disabled);
  }, {allowSignalWrites: true})

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.value.set(obj);
      this.textInput.set(obj);
    } else {
      const value = this.dateParser.parse(obj);
      if(value) {
        this.value.set(value);
        if(value !== obj) {
          this.onChange(value);
          this.valueChange.emit(value);
        }
      }
    }
  }

  onValueChange(value: string | null): void {
    if (this.changeTrigger() === 'blur')
      this.setValue(value, true);
    if(this.value())
      this.textInput.set(null);
  }

  onTextInput(value: string | null): void {
    switch (this.changeTrigger()) {
      case 'input':
        this.textInput.set(value);
        this.setValue(value, true);
        break;
      default:
        this.textInput.set(value);
        break;
    }
  }

  setValue(value: string | null, parse: boolean): void {
    const prevValue = this.value();

    if (!value)
      value = null;
    else if (parse)
      value = this.dateParser.parse(value) ?? null;

    if (equals(value, prevValue))
      return;
    this.value.set(value);
    this.onChange(value);
    this.valueChange.emit(value);
  }


  async openDateRangePickerModal(): Promise<void> {
    this.onTouch();

    const value: DateRange = {from: null, to: null};
    value[this.field] = this.value();

    const otherControl = this.otherControl();
    const otherField = this.field === 'from' ? 'to' : 'from';
    if(otherControl)
      value[otherField] = otherControl.value();

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.dateRange,
      { value: value, dismiss: true, startField: this.field }
    );

    if (!result || result === 'DISMISSED')
      return;

    this.setValue(result[this.field], false);
    this.setOtherValue(result[otherField], false);
  }

  setOtherValue(value: string | null, parse: boolean) {
    const otherControl = this.otherControl();
    if(!otherControl)
      return;
    otherControl.setValue(value, parse);
  }
}
