import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'soft-dynamic-entity-value',
    templateUrl: './entity.component.html',
    styleUrls: ['./entity.component.scss'],
    standalone: false
})
export class EntityComponent implements OnInit {
  @Input() value?: any;
  @Input() format = '';

  constructor() {}

  ngOnInit(): void {}
}
