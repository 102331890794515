import { Component, OnInit } from '@angular/core';
import {
  DurationDefinition,
  StringDefinition,
} from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
    selector: 'soft-dynamic-duration-input',
    templateUrl: './duration-input.component.html',
    styleUrls: ['./duration-input.component.scss'],
    standalone: false
})
export class DurationInputComponent
  extends DynamicInputAtom<DurationDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
