import { Component, computed, EventEmitter, inject, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IconComponent,
  L10nModule,
  UiCorePipesModule,
  WithModal,
} from '@softline/ui-core';
import {
  FilterAndSortComponent,
  FilterConfig,
  SortConfig,
} from '@softline/application';
import { Filter, FilterService, Sort, SortService } from '@softline/core';
import {
  DocumentInformation,
  UserInformation,
} from '../../types/document-information';

@Component({
  selector: 'soft-document-information-dialog',
  imports: [
    CommonModule,
    L10nModule,
    UiCorePipesModule,
    IconComponent,
    FilterAndSortComponent,
  ],
  templateUrl: './document-information-dialog.component.html',
  styleUrl: './document-information-dialog.component.scss',
})
export class DocumentInformationDialogComponent extends WithModal() {
  filter = signal<Filter | null>(null);
  sort = signal<Sort | null>(null);
  _changedAt = signal<string | null>(null);
  _detail = signal<DocumentInformation | null>(null);

  set changedAt(value: string) {
    this._changedAt.set(value);
  }

  set detail(value: DocumentInformation) {
    this._detail.set(value);
  }

  downloadFn?: () => Promise<void>;

  filteredItems: Signal<UserInformation[]> = computed(() => {
    return this.sortService.sort(
      this.filterService.filter(
        this._detail()?.userInformationResponseList ?? [],
        this.filter()
      ),
      this.sort()
    );
  });

  private sortService = inject(SortService);
  private filterService = inject(FilterService);

  sortConfig: SortConfig = {
    properties: [
      {
        property: 'anwender.anwender',
        title: 'Benutzername',
        comparer: (a, b) => (a as string).localeCompare(b as string),
      },
      {
        property: 'anwender.anwenderName',
        title: 'Name',
        comparer: (a, b) => (a as string).localeCompare(b as string),
      },
      {
        property: 'webDocFavorit.favourite',
        title: 'Favorit',
        comparer: (a, b) => Number(a) - Number(b),
      },
      {
        property: 'downloadHistory.downloadedAt',
        title: 'Gedownloaded',
      },
    ],
  };

  filterConfig: FilterConfig = {
    properties: [
      {
        property: 'anwender.anwender',
        title: 'Benutzername',
      },
      {
        property: 'anwender.anwenderName',
        title: 'Name',
      },
      {
        property: 'downloadHistory.downloadedAt',
        title: 'Gedownloaded',
      },
      {
        property: 'webDocFavorit.favourite',
        title: 'Favorit',
      }
    ],
  };

  constructor() {
    super();
  }

  async download(): Promise<void> {
    await this.downloadFn?.();
  }
}
