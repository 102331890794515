import { DownloadRequestOptions, ResourceService } from '../abstraction';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestEvent } from '../request';

export class WrappedResourceService<TLocation>
  implements ResourceService<TLocation>
{
  constructor(
    protected service: ResourceService<TLocation>,
    private converter: (response: any) => any
  ) {}

  get<T, TPayload>(
    location: any,
    payload: TPayload | undefined
  ): Observable<T> {
    return this.service
      .get(location, payload)
      .pipe(map((o) => this.converter(o)));
  }

  create<T, TResponse>(
    location: TLocation,
    resource: T
  ): Observable<TResponse> {
    return this.service
      .create<T, TResponse>(location, resource)
      .pipe(map((o) => this.converter(o)));
  }

  update<T, TResponse>(
    location: TLocation,
    resource: T
  ): Observable<TResponse> {
    return this.service
      .update<T, TResponse>(location, resource)
      .pipe(map((o) => this.converter(o)));
  }

  patch<T, TResponse>(
    location: TLocation,
    changes: Partial<T>
  ): Observable<TResponse> {
    return this.service
      .patch<T, TResponse>(location, changes)
      .pipe(map((o) => this.converter(o)));
  }

  delete<TResponse, TPayload>(
    location: TLocation,
    payload: TPayload | undefined
  ): Observable<TResponse> {
    return this.service
      .delete<TResponse, TPayload>(location, payload)
      .pipe(map((o) => this.converter(o)));
  }

  download<T>(
    location: TLocation,
    payload: unknown | undefined,
    options?: DownloadRequestOptions
  ): Observable<RequestEvent<Blob | ArrayBuffer>> {
    return this.service
      .download<T>(location, payload, options)
  }

  upload<T, TResponse>(
    location: TLocation,
    resource: T
  ): Observable<RequestEvent<TResponse>> {
    return this.service.upload<T, TResponse>(location, resource).pipe(
      map((o) => {
        if (o.type === 'response') o.response = this.converter(o.response);
        return o;
      })
    );
  }
}
