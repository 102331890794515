import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef, HostBinding,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { serializeHtmlCharacterEntitiesFunction } from '../../../functions/html-character-entities.function';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../../i18n/i18n.module';

@Component({
    selector: 'soft-input',
    imports: [CommonModule, UiCorePipesModule, I18nModule],
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent implements OnInit, ControlValueAccessor {
  private onChange: Function = () => {};
  onTouch: Function = () => {};

  @Input() value: string | null | undefined;
  @Input() type: 'text' | 'email' | 'url' = 'text';
  @Output() valueChange: EventEmitter<string | null | undefined> =
    new EventEmitter<string | null | undefined>();

  @Input() placeholder?: string | null;
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() autocomplete?: string;
  @Input() escapeHtml = false;

  @Output() blur = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setValue(value: string | null): void {
    if (value && this.escapeHtml)
      value = serializeHtmlCharacterEntitiesFunction(value);
    this.value = value;

    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
