import { Component, contentChild, input, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'soft-page-tab',
    imports: [CommonModule],
    templateUrl: './page-tab.component.html',
    styleUrl: './page-tab.component.scss'
})
export class PageTabComponent {
  name = input.required<string>();
  title = input.required<string>();
  icon = input<string>();
  template = viewChild(TemplateRef);
  contentTemplate = contentChild(TemplateRef);
}
