import { Component, Input } from '@angular/core';

@Component({
    selector: 'soft-dynamic-icon-value',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    standalone: false
})
export class IconComponent {
  @Input() value?: any;

  constructor() {}
}
