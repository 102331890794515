import { Component } from '@angular/core';
import { ResetDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
    selector: 'lib-reset-input',
    templateUrl: './reset-input.component.html',
    styleUrls: ['./reset-input.component.scss'],
    standalone: false
})
export class ResetInputComponent extends DynamicInputAtom<ResetDefinition> {
  constructor() {
    super();
  }
}
