import { Component, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import moment from 'moment';
import {
  JwtAuthenticationStore,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION,
} from '@softline/auth';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
} from '@softline/ui-core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'soft-login-app',
    templateUrl: './login-app.component.html',
    styleUrls: ['./login-app.component.scss'],
    imports: [RouterModule]
})
export class LoginAppComponent implements OnInit {
  year = '2000';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.year = moment().format('YYYY');
    const isExpired = this.store.get(
      SOFTLINE_FEATURE_JWT_AUTHENTICATION,
      JwtAuthenticationStore.getters.expired
    );
    if (isExpired)
      this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.warning,
        '#LOGIN.MESSAGES.EXPIRED'
      );
  }
}
