import { EnvironmentProviders, Provider } from '@angular/core';
import { provideStore } from './store/store.providers';
import { provideResources } from './data/resource.providers';
import {
  ConnectionHttpService,
  DateService,
  filterProviders,
  Logger,
  Mediator, MomentDateService, ResponseResult,
  SOFTLINE_CONFIG_COOKIE_PREFIX, SOFTLINE_SERVICE_HTTP, SOFTLINE_SERVICE_UUID,
  store2Providers,
  telemetryProviders, WrappedResourceService
} from '@softline/core';
import { languageProviders } from './language/language.providers';
import { sortProviders } from './sort/sort.providers';
import { v4 as uuidv4 } from 'uuid';

export const provideCore: () => Array<Provider | EnvironmentProviders> = () => {
  return [
    provideStore([]),
    provideResources(),

    Logger,
    Mediator,

    store2Providers,
    languageProviders,
    filterProviders,
    sortProviders,
    telemetryProviders,

    { provide: SOFTLINE_CONFIG_COOKIE_PREFIX, useValue: 'Softline' },
    { provide: SOFTLINE_SERVICE_UUID, useValue: () => uuidv4() },
    { provide: DateService, useClass: MomentDateService },
    {
      provide: SOFTLINE_SERVICE_HTTP,
      useFactory: (service: ConnectionHttpService) =>
        new WrappedResourceService(
          service,
          (response: ResponseResult<any>) => response?.result
        ),
      deps: [ConnectionHttpService],
    },
    ];
}
