import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Store } from "@softline/core";
import { Parser, SOFTLINE_SERVICE_NUMBER_PARSER } from "../../../ui-core.tokens";
import * as LocalizationStore from "../../../l10n/localization.store";
import { SOFTLINE_CONFIG_DEFAULT_LOCALE, SOFTLINE_FEATURE_LOCALIZATION } from "../../../l10n/l10n.shared";
import { FormatPipeOptions } from "../../../l10n/pipes/format.pipe";
import { SOFTLINE_FEATURE_MODAL } from "../../../modal/modal.shared";
import * as ModalStore from "../../../modal/modal.store";
import { CommonModule } from '@angular/common';
import { IsNaNPipe } from '../../../pipes/is-nan.pipe';
import { L10nModule } from '../../../l10n/l10n.module';
import { I18nModule } from '../../../i18n/i18n.module';

@Component({
    selector: 'soft-number-input',
    imports: [CommonModule, IsNaNPipe, L10nModule, I18nModule],
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberInputComponent implements OnInit, ControlValueAccessor {
  private onChange: Function = () => {};
  onTouch: Function = () => {};

  @Input('tabindex') tabIndex: string | number = 0;

  input: string | null = null;
  @Input() value: number | null | undefined;
  @Output() valueChange: EventEmitter<number | null | undefined> =
    new EventEmitter<number | null | undefined>();
  @Input() format?: string;
  @Input() formatOptions?: FormatPipeOptions;
  @Input() textAlign: 'left' | 'right' = 'left';

  @Input() placeholder?: string | null;
  @Input() @HostBinding('class.readonly') readonly = false;

  @Input() changeTrigger: 'blur' | 'input' = 'blur';

  @ViewChild('inputElement', { static: true }) private _inputElement?: {
    nativeElement: HTMLInputElement;
  };

  get inputElement(): HTMLInputElement | null {
    return this._inputElement?.nativeElement ?? null;
  }

  constructor(
    private store: Store,
    @Inject(SOFTLINE_SERVICE_NUMBER_PARSER)
    private parser: Parser<string, number>,
    @Inject(SOFTLINE_CONFIG_DEFAULT_LOCALE) private defaultLocale: string
  ) {}

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onInput(value: string | null): void {
    if (this.readonly) return;

    switch (this.changeTrigger) {
      case 'input':
        this.setValue(value);
        break;
      default:
        this.input = value;
        break;
    }
  }

  onBlur(value: string | null): void {
    if (this.changeTrigger === 'blur') this.setValue(value);
  }

  setValue(value: string | null): void {
    this.input = value;

    if (!value) this.value = null;
    else {
      const locale =
        this.store.get(
          SOFTLINE_FEATURE_LOCALIZATION,
          LocalizationStore.getter.locale
        ) ?? this.defaultLocale;
      this.value = this.parser(value, locale);
    }

    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  protected readonly isNaN = isNaN;

  async openCalculatorModal(): Promise<void> {
    this.onTouch();

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.calculate,
      { value: '' + (this.value ?? ''), dismiss: true }
    );

    if (!result || result === 'DISMISSED') return;

    this.input = '' + result;
    this.value = result;

    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
