import { Component } from '@angular/core';
import { ModalBase } from '../../data/modal';

@Component({
    selector: 'soft-calculator-modal',
    templateUrl: './calculator-modal.component.html',
    styleUrls: ['./calculator-modal.component.scss'],
    standalone: false
})
export class CalculatorModalComponent extends ModalBase<number> {

  title: string | undefined;
  showCancel = false;
  params?: object;
  value: string = '';

  constructor() {
    super();
  }

  onSubmit(result: number): void {
    this.close(result);
  }
}
