import { Component, OnInit } from '@angular/core';
import { ImageInputDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
    selector: 'lib-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
    standalone: false
})
export class FileInputComponent
  extends DynamicInputAtom<ImageInputDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
