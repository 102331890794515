import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal, UiCoreModule} from "@softline/ui-core";
import {DynamicModule} from "@softline/dynamic";
import {Store} from "@softline/core";
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from "@softline/auth";
import {map} from "rxjs/operators";


@Component({
    selector: 'softline-change-baustelle',
    imports: [CommonModule, UiCoreModule, DynamicModule],
    templateUrl: './change-baustelle.dialog.html',
    styleUrls: ['./change-baustelle.dialog.scss']
})
export class ChangeBaustelleDialog implements OnInit, Modal<any> {
  close!:  (result: any) => void;
  lieferadresse!: any;

  ktostammnummer$ = this.store.observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data)
    .pipe(map(o => (o as any)?.ktostammnummer))

  constructor(private store: Store) {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  onSubmit(baustelle: any): void {
    this.close(baustelle);
  }
}
