import { Component, inject, OnInit, signal } from '@angular/core';
import {
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART
} from "../../gesamtabfrage.shared";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import { showRequestErrors, WithRefresh, WithStaticBackNavigation, WithTitle } from '@softline/application';
import { BelegStore } from "../../store/beleg.store";
import { WithBelegart } from '../../mixins/belegart.mixin';
import { Store } from '@softline/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'soft-belegarten',
    templateUrl: './belegarten.component.html',
    styleUrls: ['./belegarten.component.scss'],
    imports: [CommonModule, RouterModule, UiCoreModule]
})
export class BelegartenComponent
  extends
    WithBelegart(
    WithTitle(
    WithRefresh(
    WithStaticBackNavigation('/')
  )))
  implements OnInit {

  store = inject(Store);

  override title = signal('#GESAMTABFRAGE.TITLE');
  override subtitle = signal('#GESAMTABFRAGE.PAGES.TYPE.HEADER');

  constructor() {
    super()
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.mutations.query.setQuery, undefined);
  }

  override async refresh(): Promise<void> {
    try {
      await this.belegartStore.remote.loadMany();
    }
    catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
