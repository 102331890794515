import { Component, computed, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemStore2 } from '@softline/application';
import { MenuItemComponent } from '../menu-item/menu-item.component';

@Component({
    selector: 'soft-menu-outlet',
    imports: [CommonModule, MenuItemComponent],
    templateUrl: './menu-outlet.component.html',
    styleUrl: './menu-outlet.component.scss'
})
export class MenuOutletComponent {
  menuItemsStore = inject(MenuItemStore2);

  name = input.required<string>();

  menuItems = computed(() => {
    const items = this.menuItemsStore.values();
    const name = this.name();
    return items
      .flat()
      .filter((item) => (item.visible ?? true) && typeof item.outlet === 'string' ? item.outlet  === name : item.outlet.indexOf(name) > -1)
      .sort((a, b) => (a.priority ?? Number.POSITIVE_INFINITY) - (b.priority ?? Number.POSITIVE_INFINITY));
  })

  constructor() { }
}
