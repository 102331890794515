import { Component, OnInit } from '@angular/core';
import {
  BooleanDefinition,
  DateDefinition,
} from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
    selector: 'soft-dynamic-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss'],
    standalone: false
})
export class DateInputComponent
  extends DynamicInputAtom<DateDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
