import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { base64Decode, isDefined, Store } from "@softline/core";
import {
  ArchiveFile,
  ArchiveStore,
  BackNavigable,
  BackNavigationService,
  showRequestErrors,
  SOFTLINE_FEATURE_ARCHIVE,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from "@softline/application";
import { BehaviorSubject, firstValueFrom, Subscription } from "rxjs";
import { SOFTLINE_FEATURE_BUCHUNGSBRIEF } from "../../buchungsbrief.shared";
import { BuchungsbriefStore } from "../../store";
import { ActivatedRoute, Router } from "@angular/router";
import { PdfViewerComponent } from "@softapps/allgemein/pdf-viewer";
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
  Validators
} from "@softline/ui-core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn
} from "@angular/forms";
import { Kopfwert } from "../../types/kopfwert";
import { PdfEditorStore, SOFTLINE_FEATURE_PDF_EDITOR } from "@softapps/allgemein/pdf-editor";
import { Buchungsbrief } from "../../types/buchungsbrief";
import { Beleg } from "../../types/beleg";

@Component({
    selector: 'lib-buchungsbrief',
    imports: [
        CommonModule,
        UiCoreModule,
        PdfViewerComponent,
        ReactiveFormsModule,
    ],
    templateUrl: './buchungsbrief.component.html',
    styleUrls: ['./buchungsbrief.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuchungsbriefComponent
  implements OnInit, OnDestroy, BackNavigable
{
  private routeSubscription?: Subscription;
  private formSubscription?: Subscription;

  detailLoading = false;
  documentLoading = false;
  document?: Blob;
  isDocumentEdited = false;

  form = new FormGroup({
    belegart: new FormControl<'E' | 'H'>('E'),
    idbelegReferenz: new FormControl(0),
    begruendung: new FormControl('', [Validators.required()]),
    listBelegwerte: new FormArray([], [this.listBelegwerteValidator()]),
    files: new FormControl([], [Validators.maxLength(10, {
      validation: {isValid: false, messages: [
        {
          type: 'error',
          subject: '#BUCHUNGSBRIEF.VALIDATION.ERRORS.MAX_FILES.SUBJECT',
          message: ''
        }]}
    })]),
  });

  get listBelegwerte(): FormArray {
    return this.form.controls.listBelegwerte as unknown as FormArray;
  }

  beleg$ = this.store.observe(
    SOFTLINE_FEATURE_BUCHUNGSBRIEF,
    BuchungsbriefStore.getters.entityWithDetails
  );

  saving$ = new BehaviorSubject(false);

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private backNavigationService: BackNavigationService,
    private cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#BUCHUNGSBRIEF.TITLE'
    );

    this.routeSubscription = this.route.paramMap.subscribe(
      async (o) => await this.loadData(o.get('id'))
    );
  }

  ngOnDestroy() {
    if (this.routeSubscription && !this.routeSubscription.closed)
      this.routeSubscription.unsubscribe();
    this.routeSubscription = undefined;
    if (this.formSubscription && !this.formSubscription.closed)
      this.formSubscription.unsubscribe();
    this.formSubscription = undefined;
    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
  }

  async navigateBack(): Promise<void> {
    const params = this.route.snapshot.queryParamMap.get('from');
    if (!params) await this.router.navigate(['/']);
    else {
      const { url, extras } = JSON.parse(base64Decode(params));
      await this.router.navigate(url, extras);
    }
  }

  async loadData(id: string | null): Promise<void> {
    if(!id)
      return;
    try {
      this.detailLoading = true;
      this.documentLoading = true;

      const beleg = await this.store.dispatch(
        SOFTLINE_FEATURE_BUCHUNGSBRIEF,
        BuchungsbriefStore.actions.load,
        { id, queryParams: { belegvariante: 'KOPFERW' } }
      );
      const detail = (await this.store.dispatch(
        SOFTLINE_FEATURE_BUCHUNGSBRIEF,
        BuchungsbriefStore.actions.loadDetail,
        { id, name: 'kopfwerte', queryParams: { belegvariante: 'KOPFERW' } }
      )) as Kopfwert[];

      this.form.patchValue({ idbelegReferenz: beleg.id });
      const formWerte = this.form.controls.listBelegwerte as unknown as FormArray;
      formWerte.clear();
      for (const wert of detail ?? []) {
        formWerte.push(
          new FormGroup({
            id: new FormControl(wert.id),
            idbeleg: new FormControl(wert.idbeleg),
            ustsatz: new FormControl(wert.ustsatz),
            bemessung: new FormControl(null, {
              validators: [this.netValueValidator()],
              updateOn: 'blur',
            }),
          })
        );
      }

      const existingBuchungsbrief = this.store.get(
        SOFTLINE_FEATURE_BUCHUNGSBRIEF,
        BuchungsbriefStore.getters.buchungsbrief
      );
      if (
        existingBuchungsbrief &&
        existingBuchungsbrief.idbelegReferenz === beleg.id
      )
        this.form.patchValue(existingBuchungsbrief as any);
      else
        this.store.commit(
          SOFTLINE_FEATURE_BUCHUNGSBRIEF,
          BuchungsbriefStore.mutations.setBuchungsbrief,
          undefined
        );

      this.detailLoading = false;
      this.cdRef.detectChanges();

      const editedDocument = this.store.get(
        SOFTLINE_FEATURE_PDF_EDITOR,
        PdfEditorStore.getters.document,
        beleg.fileKey
      ) as any;
      if (editedDocument?.edited) {
        this.document = editedDocument.data;
        this.isDocumentEdited = true;
      }
      else if (beleg.fileKey)
        this.document = (
          await this.store.dispatch(
            SOFTLINE_FEATURE_ARCHIVE,
            ArchiveStore.actions.download,
            {
              file: {
                archiveKey: beleg.archiveKey,
                fileKey: beleg.fileKey,
                metaData: JSON.parse(base64Decode(beleg.fileKey)).metaData,
              },
            }
          )
        ).content;

      this.formSubscription = this.form.valueChanges.subscribe((o) =>
        this.store.commit(
          SOFTLINE_FEATURE_BUCHUNGSBRIEF,
          BuchungsbriefStore.mutations.setBuchungsbrief,
          o
        )
      );
    } catch (e) {
      this.detailLoading = false;
      showRequestErrors(this.store, e);
    } finally {
      this.detailLoading = false;
      this.documentLoading = false;
      this.cdRef.detectChanges();
    }
  }

  async onSubmit(): Promise<void> {
    const result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.ask, "#BUCHUNGSBRIEF.DIALOGS.CONFIRM");
    if(result !== "YES")
      return;
    const beleg = await firstValueFrom(this.beleg$);
    try {
      this.saving$.next(true);
      const buchungsbrief: Buchungsbrief = {
        belegart: this.form.value.belegart ?? 'E',
        idbelegReferenz: this.form.value.idbelegReferenz ?? 0,
        listBelegwerte: this.form.value.listBelegwerte ?? [],
        begruendung: this.form.value.begruendung ?? '',
      };
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_BUCHUNGSBRIEF,
        BuchungsbriefStore.actions.createBuchungsbrief,
        { buchungsbrief }
      );
      await this.uploadDocument(beleg, result);
      if (this.form.value.files && this.form.value.files.length > 0)
        await this.store.dispatch(
          SOFTLINE_FEATURE_ARCHIVE,
          ArchiveStore.actions.upload,
          {
            archiveKey: result.archiveKey,
            files: this.form.value.files,
          }
        );
      this.store.commit(
        SOFTLINE_FEATURE_BUCHUNGSBRIEF,
        BuchungsbriefStore.mutations.setBuchungsbrief,
        undefined
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        "#BUCHUNGSBRIEF.MESSAGES.SUCCESS.CREATED"
      );
      await this.navigateBack();
    } catch (e) {
      showRequestErrors(this.store, e);
    } finally {
      this.saving$.next(false);
    }
  }

  onBelegartChange(): void {
    for (const wert of this.form.controls.listBelegwerte.controls)
      (wert as FormGroup).controls['bemessung'].updateValueAndValidity();
  }

  async onEditPdf(): Promise<void> {
    if(!this.document)
      throw new Error('[BuchungsbriefComponent] onEditPdf: no document')
    const workflow = await firstValueFrom(this.beleg$);
    await this.store.dispatch(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.actions.open,
      {
        id: workflow.fileKey,
        data: this.document,
        name: 'Test',
        fromRoute: {
          url: ['/buchungsbrief', this.route.snapshot.paramMap.get('id')],
          extras: {
            queryParams: {
              from: this.route.snapshot.queryParamMap.get('from'),
            },
          },
        },
      }
    );
  }

  async onResetPdf(): Promise<void> {
    if (!this.document)
      throw new Error('[BuchungsbriefComponent] onEditPdf: no document');

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      {
        question: 'Möchten sie die das Dokument wirklich zurücksetzen?',
        dismiss: true
      });
    if(result !== 'YES')
      return;
    const workflow = await firstValueFrom(this.beleg$);

    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.mutations.remove,
      workflow.fileKey
    );
    this.documentLoading = true;
    this.document = undefined;
    this.cdRef.detectChanges();

    try {
      this.document = (
        await this.store.dispatch(
          SOFTLINE_FEATURE_ARCHIVE,
          ArchiveStore.actions.download,
          {
            file: {
              archiveKey: workflow.archiveKey,
              fileKey: workflow.fileKey,
              metaData: JSON.parse(base64Decode(workflow.fileKey)).metaData,
            },
          }
        )
      ).content;
      this.documentLoading = false;
      this.isDocumentEdited = false;
      this.cdRef.detectChanges();
    }
    catch (e) {
      this.documentLoading = false;
      showRequestErrors(this.store, e);
      this.cdRef.detectChanges();
    }
  }

  private netValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const belegart = this.form.value.belegart;
      if (
        (control.value > 0 && belegart === 'E') ||
        (control.value < 0 && belegart === 'H')
      )
        control.setValue(control.value * -1);
      return null;
    };
  }

  private listBelegwerteValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const listBelegwerte = control.value as unknown as Kopfwert[];
      if(listBelegwerte?.length > 0 && listBelegwerte.every(o => !isDefined(o.bemessung)))
        return {
          required: {
            isValid: false,
            messages: [{
              type: "error",
              subject: "#UI_CORE.VALIDATION.REQUIRED.SUBJECT",
              message: "#UI_CORE.VALIDATION.REQUIRED.MESSAGE" }]}
        };
      return null;
    };
  }

  private async uploadDocument(
    beleg: Beleg,
    buchungsbrief: Beleg
  ): Promise<void> {
    let editedDocument = this.store.get(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.getters.document,
      beleg.fileKey
    ) as any;
    if (!editedDocument?.edited) {
      editedDocument = {data: this.document};
      return;
    }

    const fileName = (JSON.parse(base64Decode(beleg.fileKey)) as ArchiveFile)
      .metaData.name;
    const file = new File([editedDocument.data], fileName);
    await this.store.dispatch(
      SOFTLINE_FEATURE_ARCHIVE,
      ArchiveStore.actions.upload,
      {
        archiveKey: buchungsbrief.archiveKey,
        files: [file],
      }
    );
    await this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.mutations.remove,
      beleg.fileKey
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      '#FREIGABE.MESSAGES.SUCCESS.UPLOAD'
    );
  }
}
