@if(view() === 'grid') {
  <div>Grid view incoming....</div>
} @else {
  <div class="c column center p-rel mt-2 mb-2 border-box h-full">
    <ng-container *ngIf="safeUrls | atIndex: selectedIndex; else noImageTemplate">
      <div
        class="gallery-navigator left"
        *ngIf="selectedIndex > 0"
        (click)="showPrevious()"
      >
        <i class="fa-regular fa-chevron-left fa-2x icon"></i>
      </div>
      <div
        *ngIf="
        $any(safeUrls | atIndex: selectedIndex) | async as url;
        else loadingTemplate
      "
        class="w-full h-full bg-contain bg-no-repeat bg-center"
        [ngStyle]="{ 'background-image': 'url(' + url + ')' }"
      ></div>
      <div
        class="gallery-navigator right"
        *ngIf="selectedIndex < safeUrls.length - 1"
        (click)="showNext()"
      >
        <i class="fa-regular fa-chevron-right fa-2x icon"></i>
      </div>
    </ng-container>
    <ng-template #noImageTemplate>
      <span class="color-light">Es ist kein Bild zum Anzeigen verfügbar</span>
    </ng-template>
    <ng-template #loadingTemplate>
      <i class="fa-regular fa-spinner fa-spin fa-4x color-light"></i>
    </ng-template>

    <div class="c p-abs center gallery-stepper-container">
      <div class="gallery-stepper">
        <div
          *ngFor="let image of images; let i = index"
          class="gallery-indicator"
          [class.active]="selectedIndex === i"
          (click)="selectedIndex = i"
        ></div>
      </div>
    </div>
  </div>
}
