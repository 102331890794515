import { Component, OnInit } from '@angular/core';
import { Modal } from '@softline/ui-core';
import { Definition } from '../../../../../data/definitions';

@Component({
    selector: 'lib-add-item-dialog',
    templateUrl: './add-item-dialog.component.html',
    styleUrls: ['./add-item-dialog.component.scss'],
    standalone: false
})
export class AddItemDialogComponent implements OnInit, Modal<unknown> {
  constructor() {}

  title = '';
  definition!: Definition;

  close: (result: unknown) => void = () => {};

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: unknown) => void): void {
    this.close = handler;
  }
}
