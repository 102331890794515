import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ObjectDefinition } from '../../data/definitions';

@Component({
    selector: 'soft-dynamic-object',
    templateUrl: './dynamic-object.component.html',
    styleUrls: ['./dynamic-object.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DynamicObjectComponent implements OnInit {
  @Input() value: any = {};
  @Input() definition?: ObjectDefinition;

  @Input() loading = false;

  constructor() {}

  ngOnInit(): void {}
}
