import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'soft-dynamic-select-value',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    standalone: false
})
export class SelectComponent implements OnInit {
  @Input() value?: any;
  @Input() options: { value: any; title: string }[] = [];

  constructor() {}

  ngOnInit(): void {}
}
