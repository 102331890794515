import {
  CreateResourceService,
  DeleteResourceService, DownloadRequestOptions,
  DownloadResourceService,
  GetResourceService,
  PatchResourceService,
  ResourceService,
  UpdateResourceService,
  UploadResourceService
} from '../../abstraction';
import { ConnectionResourceLocation } from './connection.location';
import { lastValueFrom, Observable } from 'rxjs';
import { RequestEvent } from '../../request';
import {
  createHttpResourceLocation,
  HttpLocationParameters,
  HttpResourceLocation,
} from './http.location';
import { Store } from '../../../store/store';
import { SOFTLINE_FEATURE_CONNECTION } from '../../../connection/connection.module';
import * as ConnectionStore from '../../../connection/connection.store';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { Connection } from '../../../connection/types/connection';
import { isDefined } from '../../../functions/is-defined.function';
import { from } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable()
export class ConnectionHttpService
  implements
    GetResourceService<ConnectionResourceLocation>,
    DownloadResourceService<ConnectionResourceLocation>,
    CreateResourceService<ConnectionResourceLocation>,
    UpdateResourceService<ConnectionResourceLocation>,
    DeleteResourceService<ConnectionResourceLocation>,
    PatchResourceService<ConnectionResourceLocation>,
    UploadResourceService<ConnectionResourceLocation>
{
  constructor(
    protected store: Store,
    @Inject(HttpService)
    protected service: ResourceService<HttpResourceLocation>
  ) {}

  get<T, TPayload = undefined>(
    location: ConnectionResourceLocation,
    payload?: TPayload
  ): Observable<T> {
    const httpLocation = this.convertConnectionLocationToHttpLocation(
      this.store,
      location
    );
    return from(httpLocation).pipe(
      switchMap((o) => this.service.get<T, TPayload>(o, payload))
    );
  }

  create<T, TResponse>(
    location: ConnectionResourceLocation,
    resource: T
  ): Observable<TResponse> {
    const httpLocation = this.convertConnectionLocationToHttpLocation(
      this.store,
      location
    );
    return from(httpLocation).pipe(
      switchMap((o) => this.service.create<T, TResponse>(o, resource))
    );
  }

  update<T, TResponse>(
    location: ConnectionResourceLocation,
    resource: T
  ): Observable<TResponse> {
    const httpLocation = this.convertConnectionLocationToHttpLocation(
      this.store,
      location
    );
    return from(httpLocation).pipe(
      switchMap((o) => this.service.update<T, TResponse>(o, resource))
    );
  }

  patch<T, TResponse>(
    location: ConnectionResourceLocation,
    changes: Partial<T>
  ): Observable<TResponse> {
    const httpLocation = this.convertConnectionLocationToHttpLocation(
      this.store,
      location
    );
    return from(httpLocation).pipe(
      switchMap((o) => this.service.patch<T, TResponse>(o, changes))
    );
  }

  delete<TResponse, TPayload = undefined>(
    location: ConnectionResourceLocation,
    payload?: TPayload
  ): Observable<TResponse> {
    const httpLocation = this.convertConnectionLocationToHttpLocation(
      this.store,
      location
    );
    return from(httpLocation).pipe(
      switchMap((o) => this.service.delete<TResponse, TPayload>(o, payload))
    );
  }

  download<T>(
    location: ConnectionResourceLocation,
    payload?: unknown,
    options?: DownloadRequestOptions
  ): Observable<RequestEvent<Blob | ArrayBuffer>> {
    const httpLocation = this.convertConnectionLocationToHttpLocation(
      this.store,
      location
    );

    return from(httpLocation).pipe(
      switchMap((o) => this.service.download<T>(o, payload, options))
    );
  }

  upload<T, TResponse>(
    location: ConnectionResourceLocation,
    resource: T
  ): Observable<RequestEvent<TResponse>> {
    const httpLocation = this.convertConnectionLocationToHttpLocation(
      this.store,
      location
    );
    return from(httpLocation).pipe(
      switchMap((o) => this.service.upload<T, TResponse>(o, resource))
    );
  }

  private async convertConnectionLocationToHttpLocation(
    store: Store,
    params: ConnectionResourceLocation | string
  ): Promise<HttpResourceLocation> {
    const connection = await lastValueFrom(
      store
        .observe(SOFTLINE_FEATURE_CONNECTION, ConnectionStore.getters.selected)
        .pipe(
          first((o) => !!o),
          map((o) => o as Connection)
        )
    );

    const path = isDefined(connection.basePath) ? [connection.basePath] : [];

    if (typeof params === 'string') path.push(params);
    else if (Array.isArray(params.path)) path.push(...params.path);
    else if (typeof params.path === 'string') path.push(params.path);

    const httpLocationParams: HttpLocationParameters = {
      host: connection.host,
      port: connection.port,
      path,
    };

    if (typeof params === 'object') {
      httpLocationParams.queryParams = params.queryParams;
      httpLocationParams.pathParams = params.pathParams;
    }
    return createHttpResourceLocation(httpLocationParams);
  }
}
