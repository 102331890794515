<ng-template #template>
  <soft-remote-data-container class="block overflow-hidden"
                              [ngClass]="!!dataContainerStyles() ? '' : 'sm:max-h-[50vh]'"
                              [ngStyle]="dataContainerStyles() ?? {}"
                              [data]="data()"
                              [loadingState]="loadingState()"
                              [loadingError]="loadingError()">
    <soft-master-detail [data]="data() ?? []"
                        [masterTemplate]="masterTemplate()"
                        [detailTemplate]="detailTemplate()"
                        [selected]="selectedData()"
                        (selectedChange)="this.selectionChange.emit($event)">
    </soft-master-detail>
  </soft-remote-data-container>
</ng-template>
