<soft-page>
  <div class="pb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
    @for(item of modules(); track item) {
      <div class="soft-card flex flex-col justify-center items-center cursor-pointer"
      (click)="onClick(item)">
        <div class="flex flex-col">
          <div class="flex flex-row justify-center">
            <soft-icon
              [name]="item.icon"
              class="flex fa-3x mt-1"
            ></soft-icon>
          </div>
          <div class="text-center font-bold text-xl text-ellipsis">{{item.name}}</div>
          <div class="text-center">{{item.description}}</div>
        </div>
      </div>
    }
  </div>
</soft-page>
