import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TimeParser} from '../../../services/time.parser';
import {UserAgentService} from '../../../services/user-agent.service';
import { CommonModule } from '@angular/common';
import { L10nModule } from '../../../l10n/l10n.module';
import { I18nModule } from '../../../i18n/i18n.module';

@Component({
    selector: 'soft-time-input',
    imports: [CommonModule, L10nModule, I18nModule],
    templateUrl: './time-input.component.html',
    styleUrls: ['./time-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeInputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() format?: string;
  @Input() value?: string | null;
  @Output() valueChange = new EventEmitter<string | null>();

  @Input() placeholder?: string;
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() utcOffset?: string | null;
  @Input() inputClass: string = '';

  // tslint:disable:no-empty
  private onChange: Function = () => {};
  onTouch: Function = () => {};

  constructor(
    readonly userAgentService: UserAgentService,
    private isoTimeParser: TimeParser<any>
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setValue(value: string): void {
    if (!value) this.value = value;
    else
      this.value = this.isoTimeParser.parse(value, this.utcOffset ?? undefined);
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
