import { Component, OnInit, TemplateRef } from '@angular/core';
import { Modal } from '../../data/modal';
import { NotificationResult } from '../../data/notification-result';
import { ModalOption } from '../../data/option-modal-config';

@Component({
    selector: 'lib-option-modal',
    templateUrl: './option-modal.component.html',
    styleUrls: ['./option-modal.component.scss'],
    standalone: false
})
export class OptionModalComponent<T> implements Modal<T> {
  private close!: (result: T) => void;

  title: string | undefined;
  subtitle?: string;
  content?: TemplateRef<any>;
  params?: object;

  options?: ModalOption<T>[];

  registerCloseHandler(handler: (result: T) => void): void {
    this.close = handler;
  }

  onClick(result: ModalOption<T>): void {
    if(result.disabled)
      return;
    this.close(result.value);
  }
}
