import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UploadProgress } from '../upload-progress';
import { CameraService, FileService, Store } from '@softline/core';
import { UserAgentService } from '../../../services/user-agent.service';
import { FileSourceInput } from './file-source';
import { ModalOption } from '../../../modal/data/option-modal-config';
import { SOFTLINE_FEATURE_MODAL } from '../../../modal/modal.shared';
import * as ModalStore from '../../../modal/modal.store';
import { isImage } from '../../../functions/is-image.function';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { DragDropDirective } from './directives/drag-drop.directive';
import { I18nModule } from '../../../i18n/i18n.module';

type FileSource = 'file' | 'cameraRoll' | 'camera';

@Component({
    selector: 'soft-file-input',
    imports: [CommonModule, UiCorePipesModule, DragDropDirective, I18nModule],
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileInputComponent implements ControlValueAccessor {
  _sources: FileSource[] = ['file', 'camera', 'cameraRoll'];

  @Input() set sources(value: FileSourceInput) {
    if (value === 'all') this._sources = ['file', 'camera', 'cameraRoll'];
    else if (value === 'photo') this._sources = ['camera', 'cameraRoll'];
    else this._sources = value;
  }

  @Input() selectionMode: 'single' | 'multi' = 'multi';

  @Input() value: File[] = [];
  @Output() valueChange = new EventEmitter<File[]>();

  @Input() progress: UploadProgress[] = [];

  @Input() galleryTitle?: string;
  @Input() gallerySubtitle?: string;

  @Input() readonly = false;
  @Input() disabled = false;
  @Input() accept?: string;

  @Input() inputView: 'common' | 'dragAndDrop' =
    this.userAgentService.isDesktop() ? 'dragAndDrop' : 'common';
  @Input() valueView: 'grid' | 'list' = this.userAgentService.isDesktop()
    ? 'list'
    : 'grid';

  private onChange = (_: File[]) => {};
  private onTouch = () => {};

  constructor(
    private store: Store,
    readonly userAgentService: UserAgentService,
    private fileService: FileService,
    private cameraService: CameraService,
    private cdRef: ChangeDetectorRef
  ) {}

  writeValue(files: File[]): void {
    this.value = files ?? [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setValue(files: File[]): void {
    this.value = files;
    this.onChange(this.value);
    this.onTouch();
    this.valueChange.emit(this.value);

    this.cdRef.markForCheck();
  }

  async onChooseFile(): Promise<void> {
    if (this.readonly || this.disabled) return;

    const source = await this.getSource();
    if (!source) return;

    switch (source) {
      case 'cameraRoll':
        const photoFromRoll = await this.cameraService.browse();
        if (photoFromRoll.file) this.addFiles([photoFromRoll.file]);
        break;
      case 'camera':
        const photo = await this.cameraService.snap();
        if (photo.file) this.addFiles([photo.file]);
        break;
      default:
        const files = await this.fileService.browse({
          mode: this.selectionMode,
          accept: this.accept,
        });
        if (files.length > 0) this.addFiles(files);
        break;
    }
  }

  protected async getSource(): Promise<FileSource | undefined> {
    let source: FileSource;
    if (this.userAgentService.isMobile() && this._sources.length > 1) {
      const options: ModalOption<FileSource>[] = [];
      if (this._sources.includes('file'))
        options.push({
          value: 'file',
          label: 'Dateisystem',
          icon: 'fa-regular fa-folder',
        });
      if (this._sources.includes('cameraRoll'))
        options.push({
          value: 'cameraRoll',
          label: 'Galerie',
          icon: 'fa-regular fa-images',
        });
      if (this._sources.includes('camera'))
        options.push({
          value: 'camera',
          label: 'Kamera',
          icon: 'fa-regular fa-camera',
        });

      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.choose<FileSource>(),
        {
          title: 'Quelle auswählen',
          options,
          dismiss: true,
        }
      );
      if (result === 'DISMISSED') return undefined;
      source = result;
    } else if (this._sources.length === 1) source = this._sources[0];
    else source = 'file';
    return source;
  }

  protected addFiles(files: File[]): void {
    let value = this.value;
    if (value.length > 0 && this.selectionMode === 'multi')
      for (const file of files) value = [...this.value, file];
    else if (this.selectionMode === 'single') value = [files[0]];
    else value = [...files];

    if (this.accept)
      value = value.filter((o) => {
        return (
          o.type === this.accept ||
          (this.accept?.includes(o?.type) && o?.type?.trim() !== '')
        );
      });

    this.setValue(value);
  }

  onSelectFile(filesList: FileList): void {
    if (this.readonly || this.disabled) return;
    if (filesList.length === 0) return;

    let validFiles = Array.from(filesList);

    if (this.accept && this.accept !== '') {
      validFiles = validFiles.filter((o) => o.type === this.accept);
    }

    this.addFiles(validFiles);
  }

  onDelete(index: number): void {
    if (this.readonly || this.disabled) return;

    const value = [...this.value];
    value.splice(index, 1);
    this.setValue(value);
  }

  async onShowGallery(index: number): Promise<void> {
    if (!isImage(this.value[index]?.name)) return;

    const images = this.value.filter((o) => isImage(o.name));
    index = images.indexOf(this.value[index]);
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.gallery,
      {
        images,
        title: this.galleryTitle,
        subtitle: this.gallerySubtitle,
        index,
      }
    );
  }

  getProgress(progress: UploadProgress, file: File): boolean {
    return (
      progress.file.name === file.name &&
      progress.file.lastModified === file.lastModified &&
      progress.file.size === file.size
    );
  }
}
