import { Component, computed, inject } from '@angular/core';
import { Store } from '@softline/core';
import * as ModalStore from '../modal.store';
import { SOFTLINE_FEATURE_MODAL } from '../modal.shared';
import { toSignal } from '@angular/core/rxjs-interop';
import { ModalService } from '../modal.service';
import { ModalConfig } from '../data/modal-config';

@Component({
    selector: 'soft-modal-container',
    templateUrl: './modal-container.component.html',
    styleUrls: ['./modal-container.component.scss'],
    standalone: false
})
export class ModalContainerComponent {

  service = inject(ModalService);

  configs$ = this.store.observe(
    SOFTLINE_FEATURE_MODAL,
    ModalStore.getters.items
  );
  storeConfigs = toSignal(this.configs$, {initialValue: []});
  configs = computed(() => {
    const serviceConfigs = this.service.configs();
    const storeConfigs = this.storeConfigs();
    return[...serviceConfigs, ...storeConfigs];
  })

  constructor(private store: Store) {}
}
