import {
  inject,
  Injector,
  Provider,
  StaticProvider,
  provideEnvironmentInitializer,
  EnvironmentProviders
} from '@angular/core';
import {
  LanguageStore,
  SOFTLINE_CONFIG_DEFAULT_LANGUAGE,
  SOFTLINE_CONFIG_LANGUAGES,
  SOFTLINE_FEATURE_LANGUAGE, SOFTLINE_LANGUAGE_DE, SOFTLINE_STORE_FEATURES,
  Store
} from '@softline/core';
import { LanguageService } from "./language.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LanguageInterceptor } from "./language.interceptor";
import { LanguageStore2 } from './language.store2';

export const languageProviders: Array<Provider | EnvironmentProviders> = [
  LanguageStore2,
  { provide: SOFTLINE_CONFIG_LANGUAGES, useValue: [SOFTLINE_LANGUAGE_DE]},
  { provide: SOFTLINE_CONFIG_DEFAULT_LANGUAGE, useValue: "de"},
  { provide: SOFTLINE_STORE_FEATURES, useExisting: LanguageStore2, multi: true},
  { provide: HTTP_INTERCEPTORS, useFactory: (store: Store) => new LanguageInterceptor(store), deps: [Store], multi: true},
  { provide: LanguageService, useClass: LanguageService },
  provideEnvironmentInitializer(() => {
      const oldStore = inject(Store);
      const injector = inject(Injector);
      oldStore.registerFeature(SOFTLINE_FEATURE_LANGUAGE, LanguageStore.feature, injector);

      const store = inject(LanguageStore2);
      store.init();
    })
]
