import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@softline/core';
import {
  ModalStore,
  SOFTLINE_FEATURE_MODAL,
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule,
} from '@softline/ui-core';
import { BehaviorSubject, combineAll, combineLatest, Observable, of, Subscription, tap, zip } from "rxjs";
import { filter, map } from "rxjs/operators";
import {
  SOFTLINE_CONFIG_SETTINGS,
  SOFTLINE_CONST_SETTINGS_GROUP_INTERNAL,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_SETTINGS,
  SOFTLINE_FEATURE_TITLE,
} from '../../application.shared';
import { Settings } from '../../data/settings';
import * as SettingsStore from '../settings.store';
import { BackNavigable } from '../../program/back-navigable';
import * as CommandStore from '../../program/command.store';
import { BackNavigationService } from '../../program/services/back-navigation.service';
import { CommonModule } from '@angular/common';
import * as TitleStore from '../../program/title.store';

@Component({
    selector: 'soft-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    imports: [CommonModule, UiCoreModule]
})
export class SettingsComponent implements OnInit, OnDestroy, BackNavigable {
  private subscription?: Subscription;

  selected$ = new BehaviorSubject<Settings | undefined>(undefined);
  get selected(): Settings | undefined {
    return this.selected$.value;
  }
  set selected(value: Settings | undefined) {
    this.selected$.next(value);
  }
  subTitle$ = this.selected$.pipe(map((o) => o?.title ?? ''));

  settings$ = this.store.get(SOFTLINE_FEATURE_SETTINGS, SettingsStore.getters.visibleComponents$);

  constructor(
    private store: Store,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    @Inject(SOFTLINE_CONFIG_SETTINGS) public settings: Settings[],
    @Optional() protected backNavigationService?: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#APPLICATION.SETTINGS.TITLE'
    );
    this.selected = this.settings.find(
      (o) => o.key === this.activatedRoute.snapshot.fragment
    );
    this.subscription = this.activatedRoute.fragment.subscribe((fragment) => {
      this.selected = this.settings.find((o) => o.key === fragment);
      this.store.commit(
        SOFTLINE_FEATURE_TITLE,
        TitleStore.mutations.setSubTitle,
        this.selected?.title
      );
    });

    this.backNavigationService?.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: SettingsComponent,
        commands: [
          {
            icon: 'fa-regular fa-arrow-rotate-left',
            execute: () => this.reset(),
            class: 'menu action-menu action-menu-top',
            name: '#APPLICATION.SETTINGS.RESET',
          },
        ],
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription?.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
    this.backNavigationService?.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      SettingsComponent
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
  }

  async navigateBack(): Promise<void> {
    if (this.selected)
      await this.router.navigate(['settings'], { fragment: undefined });
    else await this.router.navigate(['/']);
  }

  async save(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.actions.save
    );
  }

  async reset(): Promise<void> {
    const answer = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      { question: '#APPLICATION.SETTINGS.RESET_QUESTION' }
    );
    if (answer === 'YES')
      await this.store.dispatch(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.actions.reset
      );
  }

  async onSelect(setting: Settings | null): Promise<void> {
    await this.router.navigate(['settings'], { fragment: setting?.key });
  }
}
