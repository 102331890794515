import { Component, Inject, OnInit } from "@angular/core";
import { SOFTLINE_CONST_SETTINGS_GROUP_LOGIN } from "../../login.shared";
import { Settings, SettingsStore, SOFTLINE_CONFIG_SETTINGS, SOFTLINE_FEATURE_SETTINGS } from "@softline/application";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { firstValueFrom, map } from "rxjs";
import { ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule } from "@softline/ui-core";
import { Store } from "@softline/core";
import { CommonModule } from "@angular/common";

@Component({
    selector: 'lib-login-settings',
    templateUrl: './login-settings.component.html',
    styleUrls: ['./login-settings.component.scss'],
    imports: [CommonModule, RouterModule, UiCoreModule]
})
export class LoginSettingsComponent implements OnInit {
  settings$ = this.route.fragment.pipe(
    map((o) =>
      this.settings.find(
        (p) =>
          (!p.group || p.group === SOFTLINE_CONST_SETTINGS_GROUP_LOGIN) &&
          p.key === o
      )
    )
  );

  constructor(
    private store: Store,
    @Inject(SOFTLINE_CONFIG_SETTINGS) private settings: Settings[],
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  async openSettings(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<string>(),
      {
        title: '#LOGIN.SETTINGS',
        options: await firstValueFrom(
          this.store.get(SOFTLINE_FEATURE_SETTINGS, SettingsStore.getters.visibleComponents$)
            .pipe(map(o => o.map(p => ({ value: p.key, icon: p.icon, label: p.title }))))
        ),
        dismiss: true,
      }
    );
    if (result === 'DISMISSED') return;

    await this.router.navigate(['login', 'settings'], { fragment: result });
  }
}
