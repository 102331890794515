import { Component, HostListener, OnInit, TemplateRef } from "@angular/core";
import { Modal } from '../../data/modal';
import { QuestionResult } from '../../data/question-result';
import { DeleteResult } from '../../data/delete-result';

@Component({
    selector: 'soft-question-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.scss'],
    standalone: false
})
export class DeleteModalComponent implements Modal<DeleteResult> {
  private close!: (result: DeleteResult) => void;

  title: string | undefined;
  content?: TemplateRef<any>;
  question = '';
  showCancel = false;
  params?: object;

  registerCloseHandler(handler: (result: DeleteResult) => void): void {
    this.close = handler;
  }


  @HostListener('window:keydown.enter')
  onClick(result: DeleteResult = 'DELETE'): void {
    this.close(result);
  }
}
