<soft-page>
  <soft-page-header #pageHeader>
    @if(!smBreakpoint() && !!masterDetail?.selected()) {
      <ng-container *ngTemplateOutlet="masterTemplate() ?? null; context: {data: masterDetail?.selected()}"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="header()?.template() ?? null"></ng-container>
    }
  </soft-page-header>
  <soft-master-detail [data]="resource().value() ?? []"
                      [selectable]="resource().status() >= resourceStates.Resolved"
                      [masterTemplate]="resource().status() >= resourceStates.Resolved ? masterTemplate() : commonMasterLoadingTemplate"
                      [detailTemplate]="pageDetailTemplate"
                      [detailPlaceholder]="detailPlaceholder()"
                      [masterHeaderTemplate]="masterHeaderTemplate()"
                      [detailHeaderVisible]="false"
                      (selectedChange)="selectedChange.emit($event)"
                      #masterDetail>
  </soft-master-detail>
  <ng-content></ng-content>
</soft-page>

<ng-template #pageDetailTemplate>
  <ng-container *ngTemplateOutlet="detailTemplate(); context: { data: selected() }"></ng-container>
  <soft-menu-outlet class="bottom-menu" [name]="'detail-bottom'"></soft-menu-outlet>
</ng-template>

<ng-template #commonMasterLoadingTemplate>
  <soft-resource-container [resource]="resource()"
                           [loadingTemplate]="loadingTemplate">
  </soft-resource-container>
</ng-template>
<ng-template #loadingTemplate>
  <div *softRepeat="loadingEntries()">
    <ng-container *ngTemplateOutlet="masterLoadingTemplate() ?? defaultLoadingTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #defaultLoadingTemplate>
  <div class="flex flex-row justify-between pointer hover:bg-hover items-center gap-2 px-2">
    <div class="flex flex-row items-center gap-2">
      <soft-icon [name]="'fa-regular fa-spinner-third'"></soft-icon>
      <div class="flex flex-col">
        <div class="w-1/2 bg-background rounded"></div>
        <div class="w-1/3 bg-background rounded"></div>
      </div>
    </div>
    <soft-icon [name]="'fa-regular fa-chevron-right'"></soft-icon>
  </div>
</ng-template>
