import { EnvironmentProviders, inject, Injector, provideEnvironmentInitializer, Provider } from '@angular/core';
import { StoreConfig } from '@softline/core';
import {Store} from '../store/store'

export const provideStore: (configs: StoreConfig[]) => Array<Provider | EnvironmentProviders> = (configs: StoreConfig[]) => {
  return [
    Store,
    provideEnvironmentInitializer(() => {
    const injector = inject(Injector);
    const store = inject(Store);
    for (const config of configs) {
      const storeInjector = Injector.create({
        providers: config?.providers ?? [],
        parent: injector,
        name: config.name,
      });
      store.registerFeature(config.name, config.feature, storeInjector);
    }
  })
  ]
}

export const provideStoreFeature= (config: StoreConfig) => {
  return provideEnvironmentInitializer(() => {
    const injector = inject(Injector);
    const store = inject(Store);
    const storeInjector = Injector.create({
      providers: config?.providers ?? [],
      parent: injector,
      name: config.name,
    });
    store.registerFeature(config.name, config.feature, storeInjector);
  });
}
