import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { equals } from '@softline/core';
import { Definition, ObjectDefinition } from '../../data/definitions';
import { VirtualScrollParameters } from '../../data/virtual-scroll';

@Component({
    selector: 'soft-dynamic-list',
    templateUrl: './dynamic-list.component.html',
    styleUrls: ['./dynamic-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DynamicListComponent<T> implements OnInit {
  @Input() definition?: ObjectDefinition;
  @Input() items: T[] = [];

  @Input() selectionMode?: 'single' | 'multi';

  @Input() selectedItem?: T;
  @Output() selectedItemChange = new EventEmitter<T>();

  @Input() selectedItems: T[] = [];
  @Output() selectedItemsChange = new EventEmitter<T[]>();

  @Output() itemClick = new EventEmitter<T>();

  @Input() virtual?: VirtualScrollParameters;

  @Input() loading = false;
  @Input() skeletonRows = 10;

  constructor() {}

  ngOnInit(): void {}

  toggleSelect(item: T): void {
    switch (this.selectionMode) {
      case 'single':
        if (equals(item, this.selectedItem)) {
          this.selectedItem = undefined;
          this.selectedItems = [];
        } else {
          this.selectedItem = item;
          this.selectedItems = [item];
        }
        this.selectedItemChange.emit(this.selectedItem);
        this.selectedItemsChange.emit(this.selectedItems);
        break;
      case 'multi':
        const index = this.selectedItems.findIndex((o) => equals(item, o));
        if (index !== -1) {
          const selectedItems = [...this.selectedItems];
          selectedItems.splice(index, 1);
          this.selectedItems = selectedItems;
        } else this.selectedItems = [...this.selectedItems, item];

        this.selectedItemChange.emit(this.selectedItem);
        this.selectedItemsChange.emit(this.selectedItems);
        break;
      default:
        break;
    }
  }
}
