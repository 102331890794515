import {effect, inject, Injectable} from '@angular/core';
import {AuthenticationContextStore2} from '@softline/auth';

@Injectable()
export class BrowserPersistService {

  store = inject(AuthenticationContextStore2);

  loginEffect = effect(async () => {
    const userId = this.store.userId();

    if(!userId || !navigator.storage || !navigator.storage.persist || !navigator.storage.persisted) {
      console.debug("[BrowserPersistService] not persisting data: ", JSON.stringify({ userId, storage: !navigator.storage, persist: !navigator.storage.persist, persisted: !navigator.storage.persisted  }));
      return;
    }

    if(await navigator.storage.persisted()) {
      console.debug("[BrowserPersistService] loginEffect: Storage will not be cleared except by explicit user action (no changes made)");
      return;
    }
    const result = await navigator.storage.persist();
    if (result) {
      console.debug("[BrowserPersistService] loginEffect: Storage will not be cleared except by explicit user action");
    } else {
      console.debug("[BrowserPersistService] loginEffect: Storage may be cleared by the UA under storage pressure.");
    }
  })
}
