import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import {
  BackNavigationService,
  NetworkStatusService,
  NfcReaderStore,
  SOFTLINE_FEATURE_NFC_READER
} from '@softline/application';
import { IconComponent, MessageBarService, ModalOption, ModalService, UiCoreModule } from '@softline/ui-core';
import { AuthenticationContextStore2, LoginService } from '@softline/auth';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { DateService, Store } from '@softline/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TerminalService } from '../services/terminal.service';
import { TitleStore2 } from '../../../../../../framework/application/src/lib/program/title.store2';
import { BottomBarComponent } from '../../shell/components/bottom-bar/bottom-bar.component';

@Component({
    selector: 'soft-terminal',
    imports: [CommonModule, RouterOutlet, IconComponent, UiCoreModule, RouterModule, BottomBarComponent],
    providers: [TerminalService],
    templateUrl: './terminal.component.html',
    styleUrl: './terminal.component.scss'
})
export class TerminalComponent implements OnInit, OnDestroy{

  private subscription: Subscription | undefined;
  private terminalService = inject(TerminalService);
  private store = inject(Store);
  private router = inject(Router);
  private loginService = inject(LoginService);
  private messageBarService = inject(MessageBarService);
  private modalService = inject(ModalService);
  private dateService = inject(DateService);

  titleStore = inject(TitleStore2);
  backNavigationService = inject(BackNavigationService);
  networkStatusService = inject(NetworkStatusService);
  authenticationContextStore = inject(AuthenticationContextStore2);

  time$ = new BehaviorSubject(this.dateService.now());
  time = toSignal(this.time$);

  constructor() {
  }

  async ngOnInit(): Promise<void> {
    await this.terminalService.init();
    this.subscription = interval(1000).subscribe(
      _ => this.time$.next(this.dateService.now())
    );
  }

  ngOnDestroy() {
    if(this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  async showMenu(): Promise<void> {
    const options: ModalOption<string>[] = [];
    if(!this.authenticationContextStore.userId()) {
      options.push({icon: 'fa-regular fa-user', value: 'login', label: 'Login'});
    }
    options.push({icon: 'fa-regular fa-mobile-signal', value: 'read', label: 'NFC Read Simulieren'});
    options.push({icon: 'fa-regular fa-door-open', value: 'exit', label: 'Terminal verlassen'});
    const result = await this.modalService.choose<string>({
      title: 'Aktionen',
      dismiss: true,
      options
    })
    switch (result) {
      case 'login':
        await this.loginService.showLoginDialog();
        break;
      case 'read':
        this.store.commit(SOFTLINE_FEATURE_NFC_READER, NfcReaderStore.mutations.add, {
          type: 'read',
          serialNumber: '2059683781',
          timestamp: this.dateService.now(),
          records: []
        });
        break;
      case 'exit':
        await this.exit();
        break;
    }
  }

  async exit(): Promise<void> {
    await this.terminalService.exit();
  }
}
