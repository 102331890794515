import {
  Component,
  EventEmitter,
  forwardRef, inject,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  runInInjectionContext,
  TemplateRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { equals, SOFTLINE_SERVICE_UUID, Store } from "@softline/core";
import {
  ControlValueAccessorBase,
  EmptyEntityInputStrategy,
  EntityInputStrategy,
  UiCoreModule, ValueConverterDirective
} from "@softline/ui-core";
import {
  SOFTLINE_FEATURE_FIELD_OK, SOFTLINE_PROVIDER_FIELD_OK_CONFIG,
} from "../../dynamic.shared";
import * as FieldOkComponentStore from "../../field-ok-component.store";
import { FieldOkConfig } from "../../data/field-ok";
import { FieldOkStrategyOptions } from "./strategies/field-ok.strategy";
import { CommonModule } from "@angular/common";
import { FieldOkStrategyFactory } from "./strategies/field-ok-strategy.factory";

@Component({
    selector: 'soft-field-ok',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './field-ok.component.html',
    styleUrls: ['./field-ok.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FieldOkComponent),
            multi: true,
        },
    ]
})
export class FieldOkComponent<T>
  extends ControlValueAccessorBase
  implements OnInit, OnDestroy, ControlValueAccessor
{
  private componentId = this.uuid();

  isOpen = false;
  config: FieldOkConfig | undefined;
  strategy: EntityInputStrategy<object> = new EmptyEntityInputStrategy();

  valueConverter = inject(ValueConverterDirective, { optional: true });

  items = this.store
    .signal(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.getters.list,
      this.componentId
    );


  @Input() name = '';
  @Input() title?: string | null;
  @Input() subtitle?: string | null;
  @Input() placeholder?: string | null;
  @Input() parameters: object = {};
  @Input() readonly = false;
  @Input() template?: TemplateRef<any>;

  private _value: T | null = null;
  @Input()
  get value(): T | null {
    return this._value;
  }
  set value(value: T | null) {
    this.setValue(value, true);
  }
  @Output() valueChange = new EventEmitter<T | null>();

  constructor(
    private store: Store,
    private injector: Injector,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
    @Inject(SOFTLINE_PROVIDER_FIELD_OK_CONFIG) private configProvider: (name: string, options: {type: string}) => FieldOkConfig,
    private strategyFactory: FieldOkStrategyFactory,
  ) { super() }

  ngOnInit(): void {
    this.strategy = this.createStrategy();

    this.store.commit(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.mutations.addOrPatch,
      { key: this.componentId, component: {} }
    );
  }
  private createStrategy(): EntityInputStrategy<object, any> {
    if (!this.config)
      this.config = runInInjectionContext(this.injector, () => this.configProvider(this.name, {type: 'default'}));
    const options: FieldOkStrategyOptions = {
      componentId: this.componentId,
      title: this.title ?? '',
      subtitle: this.subtitle ?? undefined,
      config: this.config,
      type: 'single'
    }
    return runInInjectionContext(this.injector,
      () => this.strategyFactory.create(this.name, options)
    );
  }

  async ngOnDestroy(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.mutations.remove,
      this.componentId
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.actions.cancel,
      this.componentId
    );
  }

  writeValue(obj: any): void {
    if (this.valueConverter) {
      obj = this.valueConverter.converter.convert(obj);
    }

    this._value = obj;
  }

  setValue(value: any, preventValueChange: boolean = false): void {
    if (this.valueConverter) {
      value = this.valueConverter.converter.convert(value);
    }

    if (equals(this._value, value))
      return;
    this._value = value;
    this.onChange(this._value);
    this.onTouch();
    if (!preventValueChange)
      this.valueChange.emit(value);
  }
}
