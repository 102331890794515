import {
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output, signal, viewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import { isNullOrEmpty } from '@softline/core';
import { MengenEingabeFormatPipe } from './mengen-eingabe-format.pipe';

const VALID_CHARS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  '.',
  ',',
];

@Component({
    selector: 'soft-mengen-eingabe',
    imports: [CommonModule, UiCoreModule, MengenEingabeFormatPipe],
    templateUrl: './mengen-eingabe.component.html',
    styleUrls: ['./mengen-eingabe.component.scss']
})
export class MengenEingabeComponent {

  @Input() value: number = 0;
  @Input() maxValue: number | null | undefined;
  @Output() valueChange = new EventEmitter<number>();
  @Input() einheit?: string;
  @Input() float = false;
  @Input() disabled = false;
  @Input() inputClass: string | null = null;
  @Input() negativeValuesValid: boolean = false;

  readonly inputElement = viewChild('input', { read: ElementRef<HTMLInputElement> });
  readonly focused = signal(false);

  private commaPressed = false;

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // when the input is focused then we skip the keydown event logic
    // this is required in order to support android software keyboard input by using the "input" event
    // reason for using "input" instead of "keydown": keydown emits "Unidentified" on android software keyboards
    if (this.focused()) {
      return;
    }

    if (event.key === 'Unidentified' || event.key === '') {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (event.key === 'ArrowUp') {
      this.increment();
    } else if(event.key === 'ArrowDown') {
      this.decrement();
    } else {
      const inputElement = this.inputElement();

      if (!inputElement)
        return;

      let value = inputElement.nativeElement?.value ?? '';

      if (this.commaPressed) {
        this.commaPressed = false;
      }

      if (event.key === 'Backspace' && value.length > 0) {
        value = value.substring(0, value.length - 1);
      } else if (event.key === '.' || event.key === ',') {
        this.commaPressed = true;
        this.setInputElementValue(inputElement.nativeElement, `${value},`);
      } else {
        value += this.commaPressed ? `.${value}` : event.key;
        this.commaPressed = false;
      }

      this.onInput(event, value);
    }
  }

  onInput(event: Event, value: string | null): void {
    if (this.disabled)
      return;

    const formattedInput = this.formatUserInputString(value);

    if (!this.isValidKey(value)) {
      const validValue = this.removeInvalidCharacters(formattedInput);

      this.setInputElementValue(
        event.target as HTMLInputElement,
        value !== formattedInput ? this.formatToDisplayableValue(validValue) : validValue
      );

      this.value = Number(validValue);
      this.valueChange.emit(this.value);

      event.preventDefault();
      event.stopPropagation();
      return;
    }

    const numericValue = Number(formattedInput);

    if (isNaN(numericValue)) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    this.setValue(numericValue);
    this.valueChange.emit(this.value ?? 0);
  }

  onFocus(input: HTMLInputElement): void {
    this.focused.set(true);
    this.highlightInputText(input);
  }

  onBlur(input: HTMLInputElement): void {
    this.focused.set(false);

    if (this.value === 0 || isNaN(this.value) || isNullOrEmpty(input.value)) {
      this.resetToZero(input);
      return;
    }

    const formattedInput = this.formatUserInputString(input.value);
    const numericValue = Number(formattedInput);

    if (isNaN(numericValue)) {
      this.resetToZero(input);
      return;
    }

    this.setValue(numericValue);

    const validValue = this.removeInvalidCharacters(formattedInput);
    this.setInputElementValue(
      input,
      (input.value !== formattedInput)
        ? this.formatToDisplayableValue(validValue)
        : validValue
    );
  }

  increment() {
    const currentValue = this.value;
    this.setValue(currentValue + 1);

    if (currentValue !== this.value)
      this.valueChange.emit(this.value);
  }

  decrement(input?: HTMLInputElement): void {
    const currentValue = this.value;
    this.setValue(currentValue - 1);

    if (currentValue !== this.value)
      this.valueChange.emit(this.value);

    if (input && (this.value === 0 || !this.value)) {
      this.resetToZero(input);
    }
  }

  highlightInputText(input: HTMLInputElement) {
    input.setSelectionRange(0, 100000);
  }

  private isValidKey(input: string | null): boolean {
    if (!input || input.length === 0) return true;
    const entered = input[input.length - 1];
    return VALID_CHARS.includes(entered);
  }

  private formatUserInputString(value: string | null): string {
    const normalizedValue = value?.replace(/[,]/g, '.') ?? '';
    const output = normalizedValue.split('.');
    const firstPart = output.shift(); // remove and return first element
    return firstPart + (output.length ? '.' : '') + output.join('');
  }

  private removeInvalidCharacters(input: string | null): string {
    let validInput = '';

    for (const char of input ?? []) {
      if (!this.isValidKey(char))
        continue;
      validInput += char;
    }

    return validInput.length > 0 ? validInput : '0';
  }

  private setValue(value: number | null | undefined) {
    if (this.maxValue && (value || 0) > this.maxValue) {
      this.value = this.maxValue;
    } else if ((value || 0) <= 0 && !this.negativeValuesValid) {
      this.value = 0;
    } else {
      this.value = value ?? 0;
    }

    if (isNaN(this.value)) {
      this.value = 0;
    }
  }

  private formatToDisplayableValue(value: string): string {
    return value.replace('.', ',')
  }

  private resetToZero(input: HTMLInputElement): void {
    this.setInputElementValue(input, '0');
    this.setValue(0);
  }

  private setInputElementValue(element: HTMLInputElement | undefined, value: string | number): void {
    if (element) {
      element.value = typeof value === 'string' ? value : value + '';
    }
  }
}
