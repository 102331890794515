import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'soft-dynamic-boolean-value',
    templateUrl: './boolean.component.html',
    styleUrls: ['./boolean.component.scss'],
    standalone: false
})
export class BooleanComponent implements OnInit {
  @Input() value?: any;
  @Input() control?: 'checkbox' | 'switch';
  @Input() label?: string;

  constructor() {}

  ngOnInit(): void {}
}
