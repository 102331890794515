import { Component, Inject, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { ConnectionError, Store, UnauthorizedError } from "@softline/core";
import { showRequestErrors } from "@softline/application";
import {
  JwtAuthenticationStore,
  SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION
} from "@softline/auth";
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule } from "@softline/ui-core";

@Component({
    selector: 'soft-sso',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './sso.page.html',
    styleUrls: ['./sso.page.scss']
})
export class SsoPage implements OnInit {

  constructor(private store: Store,
              private router: Router,
              @Inject(SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE) private forwardRoute: string,
              private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    try {
      const token = this.route.snapshot.queryParamMap.get('token');
      const type = this.route.snapshot.queryParamMap.get('type');
      const forward = this.route.snapshot.queryParamMap.get('forward');

      const success = await this.store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.authenticateViaToken,
        {token, type}
      )
      if (!success){
        await this.router.navigate(['/login']);
        return;
      }
      await this.router.navigate([forward ?? this.forwardRoute]);
    }
    catch (e) {
      if (e instanceof UnauthorizedError)
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.warning,
          '#SSO.MESSAGES.ERROR.UNAUTHORIZED'
        );
      else if (e instanceof ConnectionError)
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.warning,
          '#SSO.MESSAGES.ERROR.NO_CONNECTION'
        );
      else
        showRequestErrors(this.store, e);
      await this.router.navigate(['/login'])
    }
  }

}
