import { Component, Injector, Input, OnInit } from "@angular/core";
import { Modal, Validators } from "@softline/ui-core";
import { ControlDefinition } from "../../../data/definitions";
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { RuleHelper } from "../../../utilities/rule.helper";

@Component({
    selector: 'lib-change-filter-value-dialog',
    templateUrl: './change-filter-value-dialog.component.html',
    styleUrls: ['./change-filter-value-dialog.component.css'],
    standalone: false
})
export class ChangeFilterValueDialogComponent<T extends object>
  implements OnInit, Modal<object>
{
  private close!: (result: object) => void;
  private _definition!: ControlDefinition;

  form = new UntypedFormGroup({});

  private _value?: T;
  @Input()
  get value(): T | undefined {
    return this._value;
  }
  set value(value: T | undefined) {
    if (value === this._value) return;

    this._value = value;
    if (value) this.form.patchValue(value);
  }

  get definition(): ControlDefinition {
    return this._definition;
  }
  set definition(value: ControlDefinition) {
    this._definition = value;
    if (value)
      this.form = new UntypedFormGroup({
        [value.name]: this.createControl(value),
      });
    else this.form = new UntypedFormGroup({});
  }

  constructor(private injector: Injector) {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: object) => void): void {
    this.close = handler;
  }

  onSubmit(entity: T): void {
    this._value = { ...this._value, ...entity };
    this.close(this._value);
  }

  onNativeSubmit(event: Event): void {
    event?.stopPropagation();
  }

  createControl(definition: ControlDefinition): UntypedFormControl {
    let updateOn: 'blur' | 'change' | 'submit' = 'blur';
    let defaultValue: unknown = null;
    switch (definition.type) {
      case 'select':
        updateOn = 'change';
        break;
      case 'boolean':
        updateOn = 'change';
        defaultValue = false;
        break;
      case 'date':
        updateOn = 'change';
        break;
      case 'string':
        updateOn = 'change';
        break;
    }
    const validators: ValidatorFn[] = [];
    if (definition.required) validators.push(Validators.required());
    if (definition.validations)
      validators.push(RuleHelper.getValidator(definition.validations, this.injector));

    const control = new UntypedFormControl(definition.default ?? defaultValue, {
      updateOn,
      validators,
    });
    return control;
  }
}
