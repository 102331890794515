import {
  ChangeDetectorRef, inject,
  OnDestroy,
  Pipe,
  PipeTransform, signal
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@softline/core';
import * as TranslationStore from '../translation.store';
import { SOFTLINE_FEATURE_TRANSLATION } from '../i18n.shared';
import { TranslationStore2 } from '../translation.store2';

@Pipe({
    name: 'translate',
    pure: false,
    standalone: false
})
export class TranslatePipe implements PipeTransform, OnDestroy {
  private subscription?: Subscription;

  store = inject(TranslationStore2);
  cdRef = inject(ChangeDetectorRef);

  constructor() {}

  transform(
    value: string | undefined | null,
    module?: string,
    language?: string
  ): string | null | undefined {
    if (!value)
      return value;

    this.subscription = this.store.getTranslation$(value)
      .subscribe((val) => {
        this.subscription?.unsubscribe();
        this.cdRef.markForCheck();
      });

    return this.store.translate(value)();
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }
}
