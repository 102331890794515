import { Component, OnInit, TemplateRef } from '@angular/core';
import { ModalOption } from '../../data/option-modal-config';
import { Modal } from '../../data/modal';
import { Observable } from 'rxjs';

@Component({
    selector: 'soft-gallery-modal',
    templateUrl: './gallery-modal.component.html',
    styleUrls: ['./gallery-modal.component.scss'],
    standalone: false
})
export class GalleryModalComponent implements Modal<'DISMISSED'> {
  private close!: (result: 'DISMISSED') => void;

  title: string | undefined;
  subtitle?: string;
  content?: TemplateRef<any>;
  params?: object;

  images: (
    | Blob
    | string
    | Promise<Blob | string>
    | Observable<Blob | string>
  )[] = [];
  selectedIndex = 0;

  registerCloseHandler(handler: (result: 'DISMISSED') => void): void {
    this.close = handler;
  }

  onClick(result: 'DISMISSED'): void {
    this.close(result);
  }
}
