import { Component, Input, OnInit } from '@angular/core';
import { ArrayDefinition } from '../../../../data/definitions';

@Component({
    selector: 'soft-dynamic-array-value',
    templateUrl: './array.component.html',
    styleUrls: ['./array.component.scss'],
    standalone: false
})
export class ArrayComponent implements OnInit {
  @Input() values?: any[];
  @Input() definition?: ArrayDefinition;

  constructor() {}

  ngOnInit(): void {}
}
