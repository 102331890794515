import { Resource, ResourceRef, ResourceStatus, signal, WritableResource, WritableSignal } from '@angular/core';
import { of, throwError } from 'rxjs';
import { CancelledError } from '../types/errors';
import { rxResource, RxResourceOptions } from '@angular/core/rxjs-interop';

export interface CancelableResource<T> extends Resource<T> {
  cancel(): void;
}

export interface CancelableWriteableResource<T> extends WritableResource<T> {
  asReadonly(): CancelableResource<T>,
  cancel(): void;
}

export type CancelableResourceRef<T> = CancelableWriteableResource<T> & ResourceRef<T>;

export interface CancelableResourceOptions<T, R> extends RxResourceOptions<T, R> {
  skipInitialLoad?: boolean;
}

export function cancelableRxResource<T, R>(options: CancelableResourceOptions<T, R>): CancelableResourceRef<T>  {
  const cancelSignal = signal(false);
  const ref = rxResource({
    request: () => ({
      request: (options.request ? options.request(): null) as Exclude<NoInfer<R>, undefined>,
      cancel: cancelSignal(),
    }),
    loader: (param) => {
      if (options.skipInitialLoad && param.previous.status === ResourceStatus.Idle)
        return of(undefined as T);
      if(param.request.cancel)
        return throwError(() => new CancelledError());
     return options.loader({
        abortSignal: param.abortSignal,
        request: param.request.request,
        previous: param.previous
      });
    }
  });
  const returnValue = {
    ...ref,
    cancel: () => {cancelSignal.set(true)},
    reload: () => {
      if(cancelSignal()) {
        cancelSignal.set(false);
        return true;
      }
      else
        return ref.reload();
    }
  }
  return {
    ...returnValue,
    asReadonly(): CancelableResource<T> {
      return returnValue as CancelableResource<T>;
    }
  }
}
