import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'soft-dynamic-field-ok-value',
    templateUrl: './field-ok.component.html',
    styleUrls: ['./field-ok.component.scss'],
    standalone: false
})
export class FieldOkComponent implements OnInit {
  @Input() value?: any;
  @Input() format = '';

  constructor() {}

  ngOnInit(): void {}
}
