import { Component, OnInit } from '@angular/core';
import {
  NumberDefinition,
  ObjectDefinition,
} from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
    selector: 'soft-dynamic-object-input',
    templateUrl: './object-input.component.html',
    styleUrls: ['./object-input.component.scss'],
    standalone: false
})
export class ObjectInputComponent
  extends DynamicInputAtom<ObjectDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
