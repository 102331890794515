import { Component, computed, input, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore, UiCoreModule } from '@softline/ui-core';
import { NavigationExtras } from '@angular/router';
import { interpolate, Store } from '@softline/core';

export interface Step {
  header: string;
  subHeader?: string;
  icon?: string;
  state?: 'default' | 'disabled' | 'error' | 'hidden'
  routerLink?: any[] | string;
  routerLinkParams?: NavigationExtras;
  execute?: () => void;
}

@Component({
    selector: 'soft-step-header',
    imports: [CommonModule, UiCoreModule],
    templateUrl: './step-header.component.html',
    styleUrls: ['./step-header.component.scss']
})
export class StepHeaderComponent {

  steps = input<Step[]>([]);
  currentIndex = input(0);
  showLabels= input(true);

  current = computed(() => this.steps()[this.currentIndex()]);

  visibleSteps = computed(() => {
    return this.steps().filter(step => step.state !== 'hidden');
  });

  visibleIndex = computed(() => {
    let index = this.currentIndex();
    const steps = this.steps();
    while (index > -1 && steps[index].state === 'hidden')
      index--;
    return index;
  });

  visibleCurrent = computed(() => {
    return this.visibleSteps()[this.visibleIndex()];
  });

  visiblePercent = computed(() => {
    return (this.visibleIndex() + 1) * 100/(this.visibleSteps().length + 1)
  });

  /**
   * @deprecated
   */
  get stepText(): string {
    const translation = this.store.get(
      SOFTLINE_FEATURE_TRANSLATION,
      TranslationStore.getters.translation,
      '#APPLICATION.COMPONENTS.STEP_HEADER.STEP'
    );
    return interpolate(translation, {step: this.currentIndex() + 1, total: this.steps()?.length})
  }

  constructor(private store: Store) { }
}
