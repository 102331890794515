import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  Connection,
  ConnectionStore,
  createHttpResourceLocation,
  HttpResourceLocation,
  HttpService,
  ResponseRequestEvent,
  SOFTLINE_FEATURE_CONNECTION,
  Store,
} from '@softline/core';
import { filter, first, map, tap } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Pipe({
    name: 'secureImage',
    standalone: false
})
export class SecureImagePipe implements PipeTransform {
  cache = new Map();

  constructor(
    private store: Store,
    private resourceService: HttpService,
    private sanitizer: DomSanitizer
  ) {}

  async transform(url: string): Promise<SafeUrl> {
    /* const cachedImage = this.cache.get(url);
    if (cachedImage)
      return of(cachedImage);*/
    let location: HttpResourceLocation;
    if (url.startsWith('http')) location = createHttpResourceLocation({ url });
    else {
      const connection = await lastValueFrom(
        this.store
          .observe(
            SOFTLINE_FEATURE_CONNECTION,
            ConnectionStore.getters.selected
          )
          .pipe(
            first((o) => !!o),
            map((o) => o as Connection)
          )
      );
      location = createHttpResourceLocation({
        host: connection.host,
        port: connection.port,
        path: [connection.basePath ?? '', url],
      });
    }
    return await lastValueFrom(
      this.resourceService.download(location).pipe(
        filter((o: any) => o.type === 'response'),
        map((o) => o as ResponseRequestEvent<any>),
        map((val) =>
          this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(val.response)
          )
        ),
        tap((val: SafeUrl) => this.cache.set(url, val))
      )
    );
  }
}
