import { Component, HostListener } from "@angular/core";
import { Modal } from '../../data/modal';
import { NotificationResult } from '../../data/notification-result';

@Component({
    selector: 'soft-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss'],
    standalone: false
})
export class NotificationModalComponent implements Modal<NotificationResult> {
  private close!: (result: NotificationResult) => void;

  title: string | undefined;
  text = '';
  showCancel = false;
  params?: object;

  registerCloseHandler(handler: (result: NotificationResult) => void): void {
    this.close = handler;
  }

  @HostListener('window:keydown.enter')
  onClick(result: NotificationResult = 'OK'): void {
    this.close(result);
  }
}
